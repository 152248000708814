import React, { useState, useEffect, forwardRef, useImperativeHandle, } from "react";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Modal, InputNumber, Button, message, } from "antd";
import { roomTypeUpdateApi, } from "../api/api";
import {
  handleInputNumberKeyDown,
  handleInputNumberOnInput,
  formatNumber,
  disabledDate,
  disabledBeforeDate,
  disabledDateThreeMonthsAgo,
  disabledTime,
} from "../util";

export const SettingRateTimeModal = forwardRef(({ roomTypeId, roomTypeInfoOnly }, ref) => {
  const [rates, setRates] = useState({
    월: { 숙박: { 구간: [] }, 대실: { 구간: [] } },
    화: { 숙박: { 구간: [] }, 대실: { 구간: [] } },
    수: { 숙박: { 구간: [] }, 대실: { 구간: [] } },
    목: { 숙박: { 구간: [] }, 대실: { 구간: [] } },
    금: { 숙박: { 구간: [] }, 대실: { 구간: [] } },
    토: { 숙박: { 구간: [] }, 대실: { 구간: [] } },
    일: { 숙박: { 구간: [] }, 대실: { 구간: [] } },
  });
  const dayMapping = {
    MONDAY: "월",
    TUESDAY: "화",
    WEDNESDAY: "수",
    THURSDAY: "목",
    FRIDAY: "금",
    SATURDAY: "토",
    SUNDAY: "일",
  };
  const dayMappingKor = {
    "월": "MONDAY",
    "화": "TUESDAY",
    "수": "WEDNESDAY",
    "목": "THURSDAY",
    "금": "FRIDAY",
    "토": "SATURDAY",
    "일": "SUNDAY",
  };
  const [editingRow, setEditingRow] = useState(null); // 현재 드래그 추가 상태의 줄

  const [editing, setEditing] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [dragStart, setDragStart] = useState(null);
  const [currentDragRange, setCurrentDragRange] = useState([]);
  
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newPrice, setNewPrice] = useState("");
  const [editingSegment, setEditingSegment] = useState(null); // 수정 중인 세그먼트
  const [pendingSegment, setPendingSegment] = useState(null); // 새로 추가할 세그먼트 임시 저장

  useEffect(() => {
    // 데이터를 가져오는 함수
    const fetchData = async () => {
      try {
        // API 데이터 가져오기 (roomTypeInfoOnly를 실제 API 엔드포인트로 대체)
        //const data = roomTypeInfoOnly; // 이 줄은 실제 fetch로 대체 가능
        //const formattedRates = formatRates(roomTypeInfoOnly);
        formatRates(roomTypeInfoOnly);
        //setRates(formattedRates);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [roomTypeInfoOnly]);

  const formatRates = (room) => {
    // 데이터를 요일별로 구분
    room.times_pricing.forEach((pricing) => {
      pricing.day_of_week.forEach((day) => {
        const koreanDay = dayMapping[day];

        if(!pricing.use_kiosk && pricing.use_front_manager){
          pricing.sellable_time_range.forEach((range) => {
            const startHour = parseInt(range.start_at.split(":")[0], 10) * 2 + (range.start_at.includes("30") ? 1 : 0);
            const endHour = parseInt(range.end_before.split(":")[0], 10) * 2 + (range.end_before.includes("30") ? 1 : 0);
            
            handleAddSegment(koreanDay, '대실', startHour, endHour, pricing.basic_price);
          });
        }
      });
    });

    room.days_pricing.forEach((pricing) => {
      pricing.day_of_week.forEach((day) => {
        const koreanDay = dayMapping[day];
        
        if(!pricing.use_kiosk && pricing.use_front_manager){
          pricing.sellable_time_range.forEach((range) => {
            const startHour = parseInt(range.start_at.split(":")[0], 10) * 2 + (range.start_at.includes("30") ? 1 : 0);
            const endHour = parseInt(range.end_before.split(":")[0], 10) * 2 + (range.end_before.includes("30") ? 1 : 0);

            handleAddSegment(koreanDay, '숙박', startHour, endHour, pricing.basic_price);
          });
        }
      });
    });
  };

  const handleAddSegment = (day, type, start, end, price) => {
    setRates((prev) => {
      const dayData = { ...prev[day] };
      dayData[type].구간.push({ start, end, price });
      return { ...prev, [day]: dayData };
    });
  };

  const handleRemoveSegment = (day, type, index) => {
    if (!editingRow) {
      setRates((prev) => {
        const dayData = { ...prev[day] };
        dayData[type].구간.splice(index, 1);
        return { ...prev, [day]: dayData };
      });
    }
  };

  const handleUpdatePrice = (day, type, segment) => {
    /*
    const newPrice = prompt("새로운 가격을 입력하세요:", segment.price);
    if (newPrice) {
      setRates((prev) => {
        const dayData = { ...prev[day] };
        const index = dayData[type].구간.findIndex((seg) => seg === segment);
        if (index !== -1) {
          dayData[type].구간[index].price = parseInt(newPrice, 10);
        }
        return { ...prev, [day]: dayData };
      });
    }*/
    //if(!editing){
    if (!editingRow) {
      setEditingSegment({ day, type, segment });
      setNewPrice(segment.price);
      setIsModalVisible(true); // 모달 창 표시
    }
  };

  const handleMouseDown = (day, type, index) => {
    //if (editing) {
    if (editingRow && editingRow.day === day && editingRow.type === type) {
      setDragging(true);
      setDragStart({ day, type, index });
      setCurrentDragRange([index]);
    }
    /*if (editingRow && editingRow.day === day && editingRow.type === type) {
      setPendingSegment({ day, type, start: index, end: index });
    }*/
  };

  const handleMouseEnter = (index) => {
    if (dragging && dragStart !== null) {
      const start = Math.min(dragStart.index, index);
      const end = Math.max(dragStart.index, index) + 1;
      const range = Array.from({ length: end - start }, (_, i) => start + i);
      setCurrentDragRange(range);
    }
  };

  const handleMouseUp = (day, type, endIndex) => {
    if ( (editingRow && editingRow.day === day && editingRow.type === type) && dragging && dragStart !== null) {
      const start = Math.min(dragStart.index, endIndex);
      const end = Math.max(dragStart.index, endIndex) + 1;

      // 이미 설정된 구간이 있는지 확인
      const isOverlapping = rates[day][type].구간.some(
        (seg) => !(seg.end <= start || seg.start >= end)
      );

      if (isOverlapping) {
        alert("이미 설정된 구간입니다. 구간을 삭제한 후 다시 추가하세요.");
      } else {
        /*
        const price = prompt("구간 가격을 입력하세요:");
        if (price) {
          handleAddSegment(day, type, start, end, parseInt(price, 10));
        }*/

        setNewPrice(""); // 초기화
        setIsModalVisible(true); // 가격 입력 모달 표시
        setEditingSegment(null); // 기존 세그먼트와 구분
        setPendingSegment({day: day, type: type, start: start, end: end});
      }
    }
    setDragging(false);
    setDragStart(null);
    setCurrentDragRange([]); // 드래그 범위 초기화
    //setPendingSegment(null); // 임시 구간 초기화
  };

  const toggleEditing = (day, type) => {
    if (editingRow && editingRow.day === day && editingRow.type === type) {
      setEditingRow(null); // 해당 줄의 드래그 종료
    } else {
      setEditingRow({ day, type }); // 해당 줄만 드래그 추가 활성화
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    WillBeUsedInParentComponent,
  }));

  const WillBeUsedInParentComponent = async () => {
    let days_pricing_arr = [];
    let times_pricing_arr = [];
    Object.entries(rates).forEach(([day, { 숙박, 대실 }]) => {
      console.log(`요일: ${day}`);
      console.log("  숙박:");
      숙박.구간.forEach(({ start, end, price }) => {
        const startTime = `${Math.floor(start / 2)}:${start % 2 === 0 ? "00" : "30"}`;
        const endTime = `${Math.floor(end / 2)}:${end % 2 === 0 ? "00" : "30"}`;
        console.log(`    ${startTime} ~ ${endTime}: ${price}원`);
        days_pricing_arr.push({
          "name": "표준",
          "is_default": true,
          "use_front_manager": true,
          "use_kiosk": false,
          "day_of_week": [dayMappingKor[day]],
          "basic_price": price,
          "sellable_time_range": [
            {
              "start_at": startTime,
              "end_before": endTime
            }
          ]
        });
      });
      console.log("  대실:");
      대실.구간.forEach(({ start, end, price }) => {
        const startTime = `${Math.floor(start / 2)}:${start % 2 === 0 ? "00" : "30"}`;
        const endTime = `${Math.floor(end / 2)}:${end % 2 === 0 ? "00" : "30"}`;
        console.log(`    ${startTime} ~ ${endTime}: ${price}원`);
        times_pricing_arr.push({
          "name": "표준",
          "is_default": true,
          "use_front_manager": true,
          "use_kiosk": false,
          "day_of_week": [dayMappingKor[day]],
          "basic_price": price,
          "sellable_time_range": [
            {
              "start_at": startTime,
              "end_before": endTime
            }
          ]
        });
      });
    });

    let param = {
      "times_pricing": times_pricing_arr,
      "days_pricing": days_pricing_arr
    }
    console.log(param);
    
    try {
      const res = await roomTypeUpdateApi(roomTypeId, param);
      console.log(res);
      if (roomTypeId === Object.entries(res.room_types)[0][0]) {
        message.info("저장되었습니다.");
      } else {
        message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
      }
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };


  const handleModalOk = () => {
    if (!newPrice) return;

    if (editingSegment) {
      const { day, type, segment } = editingSegment;
      setRates((prev) => {
        const dayData = { ...prev[day] };
        const index = dayData[type].구간.findIndex((seg) => seg === segment);
        if (index !== -1) {
          dayData[type].구간[index].price = parseInt(newPrice, 10);
        }
        return { ...prev, [day]: dayData };
      });
    }else{
      // 새 구간 추가 로직
      const { day, type, start, end } = pendingSegment;
      handleAddSegment(day, type, start, end, parseInt(newPrice, 10));
    }
    setIsModalVisible(false); // 모달 창 닫기
    setEditingSegment(null); // 초기화
    setNewPrice(""); // 가격 초기화
    setPendingSegment(null); // 초기화
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // 모달 창 닫기
    setEditingSegment(null); // 초기화
    setNewPrice(""); // 가격 초기화
  };

  return (
    <>
      {/*
      <div className="controls">
        <Button type="primary" onClick={() => setEditing(!editing)}>{editing ? "드래그 추가 종료" : "드래그 추가"}</Button>
      </div>
      */}
      <div className="time-header mt-24">
        <div className="grid-day">요일</div>
        <div className="grid-title">구분</div>
        <div className="grid-title"></div>
        {Array.from({ length: 24 }, (_, i) => (
          <div key={i} className="time-cell">
            {i}
          </div>
        ))}
      </div>
      <div className="all-days">
        {Object.keys(rates).map((day) => (
          <div key={day} className="day-wrapper">
            {["숙박", "대실"].map((type) => (
              <div key={type} className="grid-wrapper">
                <div className="grid-day">{day}</div>
                <div className="grid-title">{type}</div>
                <div className="grid-title">
                  <Button type={editingRow?.day === day && editingRow?.type === type ? "primary" : "default"}
                          onClick={() => toggleEditing(day, type)}>
                      <EditOutlined />
                  </Button>
                </div>
                <div className="grid">
                  {Array.from({ length: 48 }, (_, index) => {
                    const segment = rates[day][type].구간.find(
                      (seg) => seg.start <= index && seg.end > index
                    );
                    const isStart = segment?.start === index;
                    const isEnd = segment?.end === index + 1;
                    const isDragging =
                      dragStart?.day === day &&
                      dragStart?.type === type &&
                      currentDragRange.includes(index);

                    return (
                      <div
                        key={index}
                        className={`grid-cell ${
                          segment ? "active" : isDragging ? "dragging" : ""
                        }`}
                        onMouseDown={() => handleMouseDown(day, type, index)}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseUp={(e) => {
                          if (
                            dragging &&
                            dragStart?.day === day &&
                            dragStart?.type === type
                          )
                            handleMouseUp(day, type, index);
                        }}
                      >
                        {isStart && (
                          <div className="price-delete">
                            <span className="price"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleUpdatePrice(day, type, segment);
                              }}
                            >
                              {segment.price}
                            </span>
                            <button
                              className="delete-button"
                              onClick={(e) => {
                                e.stopPropagation(); // 이벤트 전파 방지
                                const segIndex = rates[day][type].구간.indexOf(segment);
                                handleRemoveSegment(day, type, segIndex);
                              }}
                            >
                              <CloseOutlined />
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      <Modal
        title={editingSegment ? "가격 수정" : "요금 설정"}
        destroyOnClose={true}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        footer={[
          <Button key="cancel" onClick={handleModalCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={handleModalOk}>
            저장
          </Button>,
        ]}
        width={260}
      >
        <InputNumber
          min={0}
          step={1000}
          style={{ maxWidth: "300px" }}
          value={newPrice}
          onChange={(value) => setNewPrice(value)}
          formatter={formatNumber}
          onKeyDown={handleInputNumberKeyDown}
          onInput={handleInputNumberOnInput}
        />
      </Modal>
    </>
  );
});