import { Form, Input, Button, Checkbox, message, Modal, Divider } from "antd";
import React, { useEffect, useState } from "react";
import { api } from "../api/api";
import { useAuth } from "./AuthContext";
import { useCode } from "./CodeContext";
import { SelectAccom } from "./SelectAccom";

export const Login = () => {
  const [accomIdList, setAccomIdList] = useState([]);
  let [accessibility_admin, setAccessibility_admin] = useState(false);
  let [token, setToken] = useState("");
  const { login, login_noaccom } = useAuth();
  const { codeload } = useCode();
  const [form] = Form.useForm();

  // 로컬 스토리지에서 ID와 비밀번호 불러오기
  useEffect(() => {
    const savedUsername = localStorage.getItem("savedUsername");
    // const savedPassword = localStorage.getItem("savedPassword");
    if (savedUsername) {
      form.setFieldsValue({ username: savedUsername });
    }
    // if (savedPassword) {
    //   form.setFieldsValue({ password: savedPassword });
    // }
  }, [form]);

  const onFinish = async (values) => {
    try {
      const response = await api.post(`token/user`, {
        app_id: "LAZ68DrmYpWDx41LpQPY",
        user_id: values.username,
        password: values.password,
      });
      
      if (Object.entries(response.data.users)[0][1].accessibility_admin?.accom_mgt?.includes("R")){
        login_noaccom(response.data); // AuthContext : 로그인 상태 업데이트
      
        setToken(response.data.token);
        setAccomIdList(Object.entries(response.data.users)[0][1].accessible_accom_ids);
        setAccessibility_admin(true);

        showModal();
      } else if (Object.entries(response.data.users)[0][1].accessible_accom_ids.length > 1){
        login_noaccom(response.data); // AuthContext : 로그인 상태 업데이트
      
        setToken(response.data.token);
        setAccomIdList(Object.entries(response.data.users)[0][1].accessible_accom_ids);

        showModal();
      }else{
        login(response.data);

        const accomId = Object.entries(response.data.users)[0][1].accessible_accom_ids[0];
        if (accomId !== null) {
          const resRoomCode = await api.get(`/room/all-by-accom/${accomId}`, {
            params: {},
            headers: { Authorization: `Bearer ${response.data.token}` },
          });
          console.log(resRoomCode);
          codeload("room", resRoomCode.data.rooms);

          const resRoomTypeCode = await api.get(
            `/room-type/all-by-accom/${accomId}`,
            {
              params: {},
              headers: { Authorization: `Bearer ${response.data.token}` },
            }
          );
          codeload("roomType", resRoomTypeCode.data.room_types);
        }
        codeload("state", {
          GENERATED: "자동예약",
          RESERVED: "정상예약",
          USING: "사용중",
          CANCELED: "예약취소",
          USED: "사용완료",
          EXPIRED: "시간초과"
        });
        codeload("stayType", {
          HOURS: "대실",
          DAYS: "숙박",
          LONG_DAYS: "장기",
          OTHER: "기타",
        });
        codeload("agentType", {
          YANOLJA: "야놀자(M)",
          YANOLJA_HOTEL: "야놀자(H)",
          GOOD_CHOICE: "여기어때(M)",
          GOOD_CHOICE_HOTEL: "여기어때(H)",
          NAVER: "네이버",
          AIR_BNB: "에어비엔비",
          AGODA: "아고다",
          EXPEDIA: "익스피디아",
          ONDA: "온다",
          BOOKING_HOLDINGS: "부킹닷컴",
          KULL_STAY: "꿀스테이",
          DDNAYO: "떠나요",
          FRONT: "프론트",
          OTHER: "기타",
        });
        codeload("stateType", {
          EMPTY: "퇴실",
          USING: "입실",
          OUTING: "외출",
          CLEANING: "청소중",
          INSPECT_ORDER: "점검요청",
          INSPECTING: "점검중",
          CLEAN_ORDER: "청소요청",
        })
        // window.location.reload();
        window.location.href = "/";
      }
      
      // 체크박스가 선택되어 있다면 ID와 비밀번호 저장
      if (values.remember) {
        localStorage.setItem("savedUsername", values.username);
        // localStorage.setItem("savedPassword", values.password);
      } else {
        localStorage.removeItem("savedUsername");
        // localStorage.removeItem("savedPassword");
      }
    } catch (error) {
      console.error("로그인 실패:", error);
      message.error("로그인 실패: 아이디 또는 비밀번호가 일치하지 않습니다.");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const colseModal = () => {
    setIsModalOpen(false);
  };

  const showchatModal = () => {
    Modal.info({
      title: '준비중입니다',
      content: (
        <div>
          <p>현재 채팅 상담 서비스는 준비 중입니다.</p>
        </div>
      ),
      onOk() {},
    });
  };

  const telClick = () => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent); // 모바일 여부 확인
  
    if (isMobile) {
      window.location.href = 'tel:16005356'; // 모바일에서는 전화 연결
    } else {
      Modal.info({
        title: '상담문의',
        content: (
          <div>
            <p>문의 : 1600-5356 | phpkim@icrew.kr</p>
            <p>상담 운영시간 : 09:00 ~ 18:00</p>
            <p>점심시간 : 12:00 ~ 13:00 / 주말 및 공휴일 휴무</p>
          </div>
        ),
        onOk() {},
      });
    }
  };

  return (
    <div className="login-form2" >
      {/* <h2>MY CREW</h2> */}
      <h2><img src={"/icrew_logo.png"} alt={""}/></h2>
      
      <Form
        name="basic"
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
        autoComplete="off"
      >
        {/* <p>아이디</p> */}
        <Form.Item
          //label="ID"
          name="username"
          rules={[{ required: true, message: "아이디를 입력하세요!" }]}
        >
        <Input placeholder="아이디"  size="large" style={{ height: '45px' }}/>
        </Form.Item>
        
        {/* <p>비밀번호</p> */}
        <Form.Item
          //label="PW"
          name="password"
          rules={[{ required: true, message: "비밀번호를 입력하세요!" }]}
        >
        <Input.Password placeholder="비밀번호" size="large" style={{ height: '45px' }}/>
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>아이디 저장</Checkbox>
        </Form.Item>


          <Button type="primary" htmlType="submit" block style={{ height: '45px', fontWeight: 'bold', background : '#3f48cc' }}>
            로그인
          </Button>
        
      </Form>
    <Divider className="mtb-16"/>
    {/* <Button type="default" block onClick={showchatModal} >
        <img 
        src="/shop_icon.png" // 아이콘 이미지 경로
        alt="채팅상담" 
        style={{ width: '20px', height: '20px', marginRight: '8px' }} // 아이콘 크기 및 여백
        />
        상담문의
        </Button>
    <Divider className="mtb-16"/> */}
      <div className="grid-col-2 w-full mb-10">
        <Button type="default" block onClick={showchatModal} >
        <img 
        src="/kakao_talk.png" // 아이콘 이미지 경로
        alt="채팅상담" 
        style={{ width: '20px', height: '20px', marginRight: '8px' }} // 아이콘 크기 및 여백
        />
        채팅상담
        </Button>
        <Button type="default" block onClick={telClick}>
        <img 
        src="/telephone_icon.png" // 아이콘 이미지 경로
        alt="유선상담" 
        style={{ width: '20px', height: '20px', marginRight: '8px' }} // 아이콘 크기 및 여백
        />
        유선상담
        </Button>
  
      </div>
      <Divider className="mtb-16"/>
      {/* <img 
        src="/shop_icon.png" // 아이콘 이미지 경로
        alt="홈" 
        style={{ width: '19px', height: '19px', marginRight: '5px' }} // 아이콘 크기 및 여백
        />  */}
      <Button type="default" href="https://www.icrew.kr/" target="_blank" block style={{textDecoration: 'none', height : '40px' , borderRadius: '20px', marginBottom: '10px'}}>
        <img 
        src="/shop_icon.png" // 아이콘 이미지 경로
        alt="홈" 
        style={{ width: '18px', height: '18px', marginRight: '8px' }} // 아이콘 크기 및 여백
        /> 
        <b>(주)아이크루컴퍼니 바로가기</b>
      </Button>
      {/* <Button type="default" href="https://www.thehyoosik.com/" target="_blank" block style={{textDecoration: 'none', height : '40px' , borderRadius: '20px', marginBottom: '10px'}}>
        <b>위탁운영문의 더휴식 바로가기</b>
      </Button>
      <Button type="default" href="http://icrewas.jebee.net/" target="_blank" block style={{textDecoration: 'none', height : '40px' , borderRadius: '20px', marginBottom: '10px'}}>
        <b>AS접수 바로가기</b>
      </Button> */}
      {/*<h5>관리자 권한 로그인 추가 예정</h5>*/}
      {/* <p className="login_detail" style={{ display: 'flex', alignItems: 'center' }}>
        <b style={{ marginRight: '8px', color : '#3f48cc' }}>상담문의</b>
        <Link to="https://www.icrew.kr/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', textDecoration: 'none', color: '#3f48cc' ,display: 'flex', alignItems: 'center'}}>
        <img 
        src="/shop_icon.png" // 아이콘 이미지 경로
        alt="홈" 
        style={{ width: '18px', height: '18px', marginRight: '8px' }} // 아이콘 크기 및 여백
        />
        (주)아이크루컴퍼니 
        </Link>
      </p> */}

      
      {/* <p className="login_detail">사업자등록번호 : 713-88-01345 | 대표 : 김용수</p>
      <p className="login_detail">주소 : 인천광역시 부평구 부평대로 283, B동 703호</p>
      <p className="login_detail">문의 : 1600-5356 | phpkim@icrew.kr</p> */}
      
      {/*<Link to="https://www.icrew.kr/" target="_blank"><p>(주)아이크루컴퍼니</p></Link>*/}

      <Modal
        destroyOnClose={true}
        title="업소 선택"
        open={isModalOpen}
        onCancel={colseModal}
        footer={null}
        width={1000}
        maskClosable={false}
      >
        <SelectAccom accessibility_admin={accessibility_admin} accomIdList={accomIdList} token={token} colseModal={colseModal} />
      </Modal>
    </div>
  );
};
