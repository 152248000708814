import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import dayjs from "dayjs";
import koKR from "antd/lib/locale/ko_KR";
import { roomSalePaymentApi } from "../api/api";
import { Button, ConfigProvider, DatePicker, Table, message } from "antd";
import { disabledDate, disabledTime, formatNumber } from "../util";

const { RangePicker } = DatePicker;

export const SalesInquiry2 = () => {
  // API 테스트
  const [roomSalePaymentList, setRoomSalePaymentList] = useState([]);
  const [columns, setColumns] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [pagination, setPagination] = useState({ current: 1, pageSize: 15 });



  const [dateRange, setDateRange] = useState([
    dayjs(dayjs().format("YYYY-MM-DD") + " 00:00:00"),
    dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59")
  ]);
  const [startDateTime, setStartDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 00:00:00"));
  const [endDateTime, setEndDateTime] = useState(dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59"));

  const [totalSummary, setTotalSummary] = useState({
      totalCount : 0,
      totalAccount : 0,
      totalCash : 0,
      totalCard : 0,
      totalOta : 0,
      totalReceivable : 0
  });

  const [ statistics, setStatistics ] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        //const res = await roomSalePaymentApi(startDateTime.valueOf(), endDateTime.valueOf());
        //const res = await roomSalePaymentApi(startDateTime.valueOf());
        const res = await roomSalePaymentApi(dateRange[0].valueOf(), dateRange[1].valueOf());
        
        const resArray = Object.entries(res.data);
        let tempTotalSummary = {
          totalCount : 0,
          totalAccount : 0,
          totalCash : 0,
          totalCard : 0,
          totalOta : 0,
          totalReceivable : 0
        };

        const roomPaymentArray = resArray.map(([key, value]) => {
          value.key = key;
          value.payment_time = dayjs(value.payment_time).format("YYYY-MM-DD");
          value.is_kiosk_check_in = value.is_kiosk_check_in ? "예" : "";
          tempTotalSummary = {
            totalCount : Number(tempTotalSummary.totalCount) + 1,
            totalAccount : Number(tempTotalSummary.totalAccount) + Number(value.account_pay),
            totalCash : Number(tempTotalSummary.totalCash) + Number(value.cash_pay),
            totalCard : Number(tempTotalSummary.totalCard) + Number(value.card_pay),
            totalOta : Number(tempTotalSummary.totalOta) + Number(value.ota_pay),
            totalReceivable : Number(tempTotalSummary.totalReceivable) + Number(value.receivable)
          };

          return value;
        });

        setStatistics(res.statistics);

        setTotalSummary(tempTotalSummary);
        
        setRoomSalePaymentList(roomPaymentArray);

        let room_name_filter = [
          ...new Set(roomPaymentArray.map((item) => item.room_name)),
        ].map((room_name) => ({
          value: room_name,
          text: room_name,
        }));

        let room_type_name_filter = [
          ...new Set(roomPaymentArray.map((item) => item.room_type_name)),
        ].map((room_type_name) => ({
          value: room_type_name,
          text: room_type_name,
        }));

        let stay_type_filter = [
          ...new Set(roomPaymentArray.map((item) => item.stay_type)),
        ].map((stay_type) => ({
          value: stay_type,
          text: stay_type,
        }));

        let payment_time_filter = [
          ...new Set(roomPaymentArray.map((item) => item.payment_time)),
        ].map((payment_time) => ({
          value: payment_time,
          text: payment_time,
        }));

        let is_kiosk_check_in_filter = [
          ...new Set(roomPaymentArray.map((item) => item.is_kiosk_check_in)),
        ].map((is_kiosk_check_in) => ({
          value: is_kiosk_check_in,
          text: is_kiosk_check_in,
        }));

        let account_pay_filter = [
          ...new Set(roomPaymentArray.map((item) => item.account_pay)),
        ].map((account_pay) => ({
          value: account_pay,
          text: account_pay,
        }));

        let cash_pay_filter = [
          ...new Set(roomPaymentArray.map((item) => item.cash_pay)),
        ].map((cash_pay) => ({
          value: cash_pay,
          text: cash_pay,
        }));

        // let changed_cash_filter = [
        //   ...new Set(roomPaymentArray.map((item) => item.changed_cash)),
        // ].map((changed_cash) => ({
        //   value: changed_cash,
        //   text: changed_cash,
        // }));

        let card_pay_filter = [
          ...new Set(
            roomPaymentArray.map((item) => item.card_pay)
          ),
        ].map((card_pay) => ({
          value: card_pay,
          text: card_pay,
        }));

        let card_accepter_name_filter = [
          ...new Set(
            roomPaymentArray.map((item) => item.card_accepter_name)
          ),
        ].map((card_accepter_name) => ({
          value: card_accepter_name,
          text: card_accepter_name,
        }));

        let card_no_filter = [
          ...new Set(roomPaymentArray.map((item) => item.card_no)),
        ].map((card_no) => ({
          value: card_no,
          text: card_no,
        }));

        let card_approval_no_filter = [
          ...new Set(
            roomPaymentArray.map((item) => item.card_approval_no)
          ),
        ].map((card_approval_no) => ({
          value: card_approval_no,
          text: card_approval_no,
        }));

        let ota_pay_filter = [
          ...new Set(roomPaymentArray.map((item) => item.ota_pay)),
        ].map((ota_pay) => ({
          value: ota_pay,
          text: ota_pay,
        }));

        let ota_name_filter = [
          ...new Set(roomPaymentArray.map((item) => item.ota_name)),
        ].map((ota_name) => ({
          value: ota_name,
          text: ota_name,
        }));

        let receivable_filter = [
          ...new Set(roomPaymentArray.map((item) => item.receivable)),
        ].map((receivable) => ({
          value: receivable,
          text: receivable,
        }));

        let note_filter = [
          ...new Set(roomPaymentArray.map((item) => item.note)),
        ].map((note) => ({
          value: note,
          text: note,
        }));

        setColumns([
          {
            title: <div className="text-c">객실명</div>,
            dataIndex: "room_name",
            key: "room_name",
            filterSearch: true,
            filters: room_name_filter,
            filteredValue: filteredInfo.room_name || null,
            onFilter: (value, record) =>
              record.room_name.toString() == value.toString(),
            sorter: (a, b) => a.room_name.localeCompare(b.room_name),
            sortOrder:
              sortedInfo.columnKey === "room_name" ? sortedInfo.order : null,
            ellipsis: true,
          },
          {
            title: <div className="text-c">유형명</div>,
            dataIndex: "room_type_name",
            key: "room_type_name",
            filterSearch: true,
            filters: room_type_name_filter,
            filteredValue: filteredInfo.room_type_name || null,
            onFilter: (value, record) => record.room_type_name == value,
            sorter: (a, b) => a.room_type_name.localeCompare(b.room_type_name),
            sortOrder:
              sortedInfo.columnKey === "room_type_name" ? sortedInfo.order : null,
            ellipsis: true,
            responsive: ["sm"],
          },
          {
            title: <div className="text-c">입실유형</div>,
            dataIndex: "stay_type",
            key: "stay_type",
            filterSearch: true,
            filters: stay_type_filter,
            filteredValue: filteredInfo.stay_type || null,
            onFilter: (value, record) => record.stay_type == value,
            sorter: (a, b) => a.stay_type.localeCompare(b.stay_type),
            sortOrder:
              sortedInfo.columnKey === "stay_type" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">결제일자</div>,
            dataIndex: "payment_time",
            key: "payment_time",
            filterSearch: true,
            filters: payment_time_filter,
            filteredValue: filteredInfo.payment_time || null,
            onFilter: (value, record) => record.payment_time === value,
            sorter: (a, b) => a.payment_time.localeCompare(b.payment_time),
            sortOrder:
              sortedInfo.columnKey === "payment_time" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">무인판매</div>,
            dataIndex: "is_kiosk_check_in",
            key: "is_kiosk_check_in",
            filterSearch: true,
            filters: is_kiosk_check_in_filter,
            filteredValue: filteredInfo.is_kiosk_check_in || null,
            onFilter: (value, record) => record.is_kiosk_check_in == value,
            sorter: (a, b) => a.is_kiosk_check_in.localeCompare(b.payment_time),
            sortOrder:
              sortedInfo.columnKey === "is_kiosk_check_in"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">계좌이체</div>,
            dataIndex: "account_pay",
            key: "account_pay",
            filterSearch: true,
            filters: account_pay_filter,
            filteredValue: filteredInfo.account_pay || null,
            onFilter: (value, record) => record.account_pay == value,
            sorter: (a, b) => Number(a.account_pay) < Number(b.account_pay),
            sortOrder:
              sortedInfo.columnKey === "account_pay" ? sortedInfo.order : null,
            ellipsis: true,
            align: "right",
          },
          {
            title: <div className="text-c">받은현금</div>,
            dataIndex: "cash_pay",
            key: "cash_pay",
            filterSearch: true,
            filters: cash_pay_filter,
            filteredValue: filteredInfo.cash_pay || null,
            onFilter: (value, record) => record.cash_pay == value,
            sorter: (a, b) => Number(a.cash_pay) < Number(b.cash_pay),
            sortOrder:
              sortedInfo.columnKey === "cash_pay"
                ? sortedInfo.order
                : null,
            render: (text) => formatNumber(text) || 0,
            ellipsis: true,
            align: "right",
          },
          // {
          //   title: <div className="text-c">거스름돈</div>,
          //   dataIndex: "changed_cash",
          //   key: "changed_cash",
          //   filterSearch: true,
          //   filters: changed_cash_filter,
          //   filteredValue: filteredInfo.changed_cash || null,
          //   onFilter: (value, record) => record.changed_cash == value,
          //   sorter: (a, b) => Number(a.changed_cash) < Number(b.changed_cash),
          //   sortOrder:
          //     sortedInfo.columnKey === "changed_cash" ? sortedInfo.order : null,
          //   render: (text) => formatNumber(text) || 0,
          //   ellipsis: true,
          //   align: "right",
          // },
          {
            title: <div className="text-c">카드결제</div>,
            dataIndex: "card_pay",
            key: "card_pay",
            filterSearch: true,
            filters: card_pay_filter,
            filteredValue: filteredInfo.card_pay || null,
            onFilter: (value, record) =>
              record.card_pay == value,
            sorter: (a, b) =>
              Number(a.card_pay) <
              Number(b.card_pay),
            sortOrder:
              sortedInfo.columnKey === "card_pay"
                ? sortedInfo.order
                : null,
            render: (text) => formatNumber(text) || 0,
            ellipsis: true,
            align: "right",
          },
          {
            title: <div className="text-c">카드사명</div>,
            dataIndex: "card_accepter_name",
            key: "card_accepter_name",
            filterSearch: true,
            filters: card_accepter_name_filter,
            filteredValue: filteredInfo.card_accepter_name || null,
            onFilter: (value, record) =>
              record.card_accepter_name == value,
            sorter: (a, b) =>
              a.card_accepter_name.localeCompare(
                b.card_accepter_name
              ),
            sortOrder:
              sortedInfo.columnKey === "card_accepter_name"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">카드번호</div>,
            dataIndex: "card_no",
            key: "card_no",
            filterSearch: true,
            filters: card_no_filter,
            filteredValue: filteredInfo.card_no || null,
            onFilter: (value, record) => record.card_no == value,
            sorter: (a, b) => a.card_no.localeCompare(b.card_no),
            sortOrder:
              sortedInfo.columnKey === "card_no"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">승인번호</div>,
            dataIndex: "card_approval_no",
            key: "card_approval_no",
            filterSearch: true,
            filters: card_approval_no_filter,
            filteredValue: filteredInfo.card_approval_no || null,
            onFilter: (value, record) =>
              record.card_approval_no == value,
            sorter: (a, b) =>
              a.card_approval_no.localeCompare(
                b.card_approval_no
              ),
            sortOrder:
              sortedInfo.columnKey === "card_approval_no"
                ? sortedInfo.order
                : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">OTA 결제</div>,
            dataIndex: "ota_pay",
            key: "ota_pay",
            filterSearch: true,
            filters: ota_pay_filter,
            filteredValue: filteredInfo.ota_pay || null,
            onFilter: (value, record) => record.ota_pay == value,
            sorter: (a, b) =>
              Number(a.ota_pay) < Number(b.ota_pay),
            sortOrder:
              sortedInfo.columnKey === "ota_pay"
                ? sortedInfo.order
                : null,
            render: (text) => formatNumber(text) || 0,
            ellipsis: true,
            align: "right",
          },
          {
            title: <div className="text-c">OTA명</div>,
            dataIndex: "ota_name",
            key: "ota_name",
            filterSearch: true,
            filters: ota_name_filter,
            filteredValue: filteredInfo.ota_name || null,
            onFilter: (value, record) => record.ota_name == value,
            sorter: (a, b) => a.ota_name.localeCompare(b.ota_name),
            sortOrder:
              sortedInfo.columnKey === "ota_name" ? sortedInfo.order : null,
            ellipsis: true,
            align: "center",
          },
          {
            title: <div className="text-c">미수금</div>,
            dataIndex: "receivable",
            key: "receivable",
            filterSearch: true,
            filters: receivable_filter,
            filteredValue: filteredInfo.receivable || null,
            onFilter: (value, record) => record.receivable == value,
            sorter: (a, b) =>
              Number(a.receivable) < Number(b.receivable),
            sortOrder:
              sortedInfo.columnKey === "receivable"
                ? sortedInfo.order
                : null,
            render: (text) => formatNumber(text) || 0,
            ellipsis: true,
            align: "right",
          },
          {
            title: <div className="text-c">결제메모</div>,
            dataIndex: "note",
            key: "note",
            filterSearch: true,
            filters: note_filter,
            filteredValue: filteredInfo.note || null,
            onFilter: (value, record) => record.note == value,
            sorter: (a, b) => a.note.localeCompare(b.note),
            sortOrder:
              sortedInfo.columnKey === "note" ? sortedInfo.order : null,
            ellipsis: true,
          },
        ]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  //}, [startDateTime, endDateTime, filteredInfo, sortedInfo]);
}, [dateRange, filteredInfo, sortedInfo]);

  const onDateRangeChange = (dates, dateStrings) => {
    if (dates) {
      const [start, end] = dates;
      const diffDays = end.diff(start, "days");
      
      if (diffDays > 90) {
        message.error("검색 기간은 최대 3개월까지만 조회 가능합니다.");
        return; // 입력된 값을 무시하고 이전 상태로 유지
      }
      setDateRange([start.startOf('day'), end.endOf('day')]);
    }
  };

  // const onChangeStartDatePicker = (date, dateString) => {
  //   if (dateString) {
  //     dateString += " 00:00:00"
  //     console.log(dateString);
  //     setStartDateTime(dayjs(dateString));
  //   }
  // };

  // const onChangeEndDatePicker = (date, dateString) => {
  //   if (dateString) {
  //     dateString += " 23:59:59"
  //     console.log(dateString);
  //     setEndDateTime(dayjs(dateString));
  //   }
  // };

  const excelDownload = () => {
    if(roomSalePaymentList.length > 0){
      const workbook = XLSX.utils.book_new();
      //const worksheet = XLSX.utils.table_to_sheet(document.getElementById("mainTable"));

      const headerOrder = [
        "객실명",
        "유형명",
        "입실유형",
        "결제일자",
        "무인판매",
        "계좌이체",
        "받은현금",
        "카드결제",
        "카드사명",
        "카드번호",
        "승인번호",
        "OTA 결제",
        "OTA명",
        "미수금",
        "결제메모"
      ];

      let ExcelDataList = roomSalePaymentList.map(item => ({
        "객실명" : item.room_name,
        "유형명" : item.room_type_name,
        "입실유형" : item.stay_type,
        "결제일자" : item.payment_time,
        "무인판매" : item.is_kiosk_check_in,
        "계좌이체" : item.account_pay,
        "받은현금" : item.cash_pay,
        "카드결제" : item.card_pay,
        "카드사명" : item.card_accepter_name,
        "카드번호" : item.card_no,
        "승인번호" : item.card_approval_no,
        "OTA 결제" : item.ota_pay,
        "OTA명" : item.ota_name,
        "미수금" : item.receivable,
        "결제메모" : item.note,
      }));

      ExcelDataList.push({
        "객실명" : "총 합계",
        "유형명" : totalSummary.totalCount + "건",
        "입실유형" : "",
        "결제일자" : "",
        "무인판매" : "",
        "계좌이체" : totalSummary.totalAccount,
        "받은현금" : totalSummary.totalCash,
        "카드결제" : totalSummary.totalCard,
        "카드사명" : "",
        "카드번호" : "",
        "승인번호" : "",
        "OTA 결제" : totalSummary.totalOta,
        "OTA명" : "",
        "미수금" : totalSummary.totalReceivable,
        "결제메모" : "",
      });

      const worksheet = XLSX.utils.json_to_sheet(ExcelDataList, { header: headerOrder });
      
      const page_title = document.getElementById("page_title");
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, page_title.innerText + dayjs().format("_YYYYMMDD_HHmmss'") + ".xlsx");
    }
};
  // 테이블 컬럼 생성

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setPagination(pagination);
  };
  // 필터 초기화 버튼
  const clearFilters = () => {
    setFilteredInfo({});
  };
  // 모두 초기화 버튼
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
    setPagination({ current: 1, pageSize: 15 });
  };
  // 날짜별 정렬 버튼
  const setPaymentDate = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "payment_time",
    });
  };

  return (
    <div className="p-16 pb-0">
      <h2 id="page_title">매출 집계</h2>

      <ConfigProvider locale={koKR}>
        <div className="flex-row flex-wrap gap-8 mt-16 mb-8">
          <RangePicker
            onChange={onDateRangeChange}
            defaultValue={[dateRange[0], dateRange[1]]}
            format="YYYY-MM-DD"
            style={{ minWidth: "16rem" }}
            placement={"bottomLeft"}
            renderExtraFooter={() => '검색 기간은 최대 3개월까지만 조회 가능합니다.'}
            />
        </div>

        {/*
        <div className="table-wrap-antd">
          <Table
            columns={columns}
            dataSource={roomSalePaymentList}
            onChange={handleChange}
            pagination={pagination}
            rowKey="key"
            // scroll={{ y: "28.8rem", x: "2600px" }}
            scroll={{ y: "28.8rem", x: "max-content" }}
            className="ant-table-respons"
            id={"mainTable"}
            summary={(pageData) => {
              let pageCount = 0;
              let pageAccount = 0;
              let pageCash = 0;
              let pageCard = 0;
              let pageOta = 0;
              let pageReceivable = 0;
              
              pageData.forEach(({account_pay, cash_pay, card_pay, ota_pay, receivable}) => {
                pageCount++;
                pageAccount += account_pay;
                pageCash += cash_pay;
                pageCard += card_pay;
                pageOta += ota_pay;
                pageReceivable += receivable;
              });
              
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>페이지 합계</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={4}>{formatNumber(pageCount) || 0}건</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}><div className="text-r">{formatNumber(pageAccount) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={3}><div className="text-r">{formatNumber(pageCash) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={4}><div className="text-r">{formatNumber(pageCard) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={6}><div className="text-r">{formatNumber(pageOta) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}></Table.Summary.Cell>
                    <Table.Summary.Cell index={8}><div className="text-r">{formatNumber(pageReceivable) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={9}></Table.Summary.Cell>
                  </Table.Summary.Row>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>총 합계</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} colSpan={4}>{formatNumber(totalSummary.totalCount) || 0}건</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}><div className="text-r">{formatNumber(totalSummary.totalAccount) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}><div className="text-r">{formatNumber(totalSummary.totalCash) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}><div className="text-r">{formatNumber(totalSummary.totalCard) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={3}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}><div className="text-r">{formatNumber(totalSummary.totalOta) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}><div className="text-r">{formatNumber(totalSummary.totalReceivable) || 0}</div></Table.Summary.Cell>
                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </div>
         */}
        <div className="table-wrap">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <colgroup>
              <col width="25%" />
              <col width="25%" />
              <col width="25%" />
              <col width="25%" />
            </colgroup>
            <thead>
              <tr>
                <th>카테고리</th>
                <th>종류</th>
                <th>건수</th>
                <th>금액</th>
              </tr>
            </thead>
            
            <tbody>
              <tr>
                <td className="text-c" rowSpan={4}>
                  <h3>대실</h3>
                </td>
                <td className="text-c">현금</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.hours_cash_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">계좌</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.hours_account_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">카드</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.hours_card_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">OTA</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.hours_ota_pay) || 0}</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-c">
                  <strong>합계</strong>
                </td>
                <td className="text-r">
                  <strong> 건</strong>
                </td>
                <td className="text-r">
                  <strong>{formatNumber(statistics.hours_cash_pay+statistics.hours_account_pay+statistics.hours_card_pay+statistics.hours_ota_pay) || 0}</strong>
                </td>
              </tr>
              {/*
              <tr>
                <td colSpan={2} className="text-c">
                  <strong>미결제</strong>
                </td>
                <td className="text-r"><strong> 건</strong></td>
                <td className="text-r">
                  <strong></strong>
                </td>
              </tr>
              )}*/}
              <tr>
                <td className="text-c" rowSpan={4}>
                  <h3>숙박</h3>
                </td>
                <td className="text-c">현금</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.days_cash_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">계좌</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.days_account_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">카드</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.days_card_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">OTA</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.days_ota_pay) || 0}</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-c">
                  <strong>합계</strong>
                </td>
                <td className="text-r">
                  <strong> 건</strong>
                </td>
                <td className="text-r">
                  <strong>{formatNumber(statistics.days_cash_pay+statistics.days_account_pay+statistics.days_card_pay+statistics.days_ota_pay) || 0}</strong>
                </td>
              </tr>
              <tr>
                <td className="text-c" rowSpan={4}>
                  <h3>장기</h3>
                </td>
                <td className="text-c">현금</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.long_days_cash_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">계좌</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.long_days_account_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">카드</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.long_days_card_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">OTA</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.long_days_ota_pay) || 0}</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-c">
                  <strong>합계</strong>
                </td>
                <td className="text-r">
                  <strong> 건</strong>
                </td>
                <td className="text-r">
                  <strong>{formatNumber(statistics.long_days_cash_pay+statistics.long_days_account_pay+statistics.long_days_card_pay+statistics.long_days_ota_pay) || 0}</strong>
                </td>
              </tr>
              <tr>
                <td className="text-c" rowSpan={4}>
                  <h3>기타</h3>
                </td>
                <td className="text-c">현금</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.other_cash_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">계좌</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.other_account_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">카드</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.other_card_pay) || 0}</td>
              </tr>
              <tr>
                <td className="text-c">OTA</td>
                <td className="text-r"> 건</td>
                <td className="text-r">{formatNumber(statistics.other_ota_pay) || 0}</td>
              </tr>
              <tr>
                <td colSpan={2} className="text-c">
                  <strong>합계</strong>
                </td>
                <td className="text-r">
                  <strong> 건</strong>
                </td>
                <td className="text-r">
                  <strong>{formatNumber(statistics.other_cash_pay+statistics.other_account_pay+statistics.other_card_pay+statistics.other_ota_pay) || 0}</strong>
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td colSpan={2} className="text-c">
                  <strong>총 합계</strong>
                </td>
                <td className="text-r"><strong>건</strong></td>
                <td className="text-r"><strong>
                  {formatNumber(statistics.hours_cash_pay+statistics.hours_account_pay+statistics.hours_card_pay+statistics.hours_ota_pay 
                  +statistics.days_cash_pay+statistics.days_account_pay+statistics.days_card_pay+statistics.days_ota_pay
                  +statistics.long_days_cash_pay+statistics.long_days_account_pay+statistics.long_days_card_pay+statistics.long_days_ota_pay
                  +statistics.other_cash_pay+statistics.other_account_pay+statistics.other_card_pay+statistics.other_ota_pay
                  ) || 0}</strong></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </ConfigProvider>
    </div>
  );
};
