import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, Modal } from "antd";
import { Card } from "./Card";
import {
  roomAllByAccomSearchApi,
  roomReserveAllByAccomSearchApi,
  roomSaleInfoOnlyApi,
  roomTypeAllByAccomSearchApi,
  getCustomConfigApi
} from "../api/api";
import { useAuth } from "../login/AuthContext";


export const Rooms = ({ roomAllList, setRoomAllList, checkedListRoomControl }) => {
  const [groups, setGroups] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [roomTypeId, setRoomTypeId] = useState([]);
  const [roomSaleId, setRoomSaleId] = useState([]);
  const [roomState, setRoomState] = useState([]);

  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [queueCount, setQueueCount] = useState(0);
  const reconnectAttempts = useRef(0);  // 재연결 시도 횟수
  const maxReconnectAttempts = 5;       // 최대 재연결 시도 횟수
  const reconnectInterval = 5000;       // 재연결 시도 간격 (5초)

  // roomAllArray를 useRef로 선언
  const roomAllArray = useRef([]);

  // 웹소켓 연결 설정 및 재연결 함수
  const connectWebSocket = () => {
    let accomId = sessionStorage.getItem("accomId");
    let event_listener_url = sessionStorage.getItem("event_listener_url");
    let token = sessionStorage.getItem("token");

    const ws = new WebSocket(event_listener_url + "?jwt="+token);

    ws.onopen = () => {
      console.log('WebSocket connected');
      reconnectAttempts.current = 0; // 연결 성공 시 재연결 시도 횟수 초기화
      
      setTimeout(() => {
        const joinMessage = JSON.stringify({
          command: 'JOIN',
          data: {
            accom_id: accomId,
            disable_event_changed_by_yourself : false,
            snapshot: [
              "ALL_ROOMS"
            ]
          }
        });
        ws.send(joinMessage);
      }, 1000);
    };

    ws.onmessage = async (event) => {
      console.log('Message from server:', event.data);
      const parsedData = JSON.parse(event.data);

      if (roomAllArray.current.length > 0 && parsedData.data) {
        let resArray = Object.entries(parsedData.data.rooms);
        
        resArray = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
            no: value.display_name === null ? "" : (value.display_name || ""),
            roomTypeId: value.room_type_id,
            roomSaleId: value.room_sale_id,
          };
        }).sort((a, b) => b.no - a.no);

        // resArray.forEach(item => {
        //   let idx = roomAllArray.findIndex(room => room.no == item.no);
        //   if(idx != -1){
        //     console.log("------------------------------------");
        //     console.log("------------------------------------");
        //     //console.log(item);
        //     console.log(roomAllArray[idx].no + " / s " 
        //       + roomAllArray[idx].value.state_summary + " / t " 
        //       + roomAllArray[idx].value.temp + " / m " 
        //       + roomAllArray[idx].value.main_power + " / a " 
        //       + roomAllArray[idx].value.aircon_power+ " / c "
        //       + roomAllArray[idx].value.clean_order+ " / i "
        //       + roomAllArray[idx].value.inspect_order+ " / ");

        //     roomAllArray[idx] = JSON.parse(JSON.stringify(item));
            
        //     console.log("=>");
        //     //console.log(roomAllArray[idx]);
        //     console.log(roomAllArray[idx].no + " / s " 
        //       + roomAllArray[idx].value.state_summary + " / t " 
        //       + roomAllArray[idx].value.temp + " / m " 
        //       + roomAllArray[idx].value.main_power + " / a " 
        //       + roomAllArray[idx].value.aircon_power+ " / c "
        //       + roomAllArray[idx].value.clean_order+ " / i "
        //       + roomAllArray[idx].value.inspect_order+ " / ");
        //   }
        // })

        const updatedRoomAllArray = roomAllArray.current.map(room => {
          const idx = resArray.findIndex(item => item.no === room.no);
          if (idx !== -1) {
            console.log("------------------------------------");
            console.log("------------------------------------");
            //console.log(item);
            console.log(room.no + " / 상태 " 
              + room.value.state_summary + " / 온도 " 
              + room.value.temp + " / 파워 " 
              + room.value.main_power + " / 에어컨 " 
              + room.value.aircon_power+ " / 청소요청 "
              + room.value.clean_order+ " / 점검요청 "
              + room.value.inspect_order+ " / ");

            // 일치하는 요소가 있으면 덮어쓰기
            const updatedRoom = { ...resArray[idx] };

            console.log(updatedRoom.no + " / 상태 " 
              + updatedRoom.value.state_summary + " / 온도 " 
              + updatedRoom.value.temp + " / 파워 " 
              + updatedRoom.value.main_power + " / 에어컨 " 
              + updatedRoom.value.aircon_power+ " / 청소요청 "
              + updatedRoom.value.clean_order+ " / 점검요청 "
              + updatedRoom.value.inspect_order+ " / ");

            return updatedRoom;
          }
          // 일치하지 않으면 기존 요소 유지
          return room;
        });
        
        // 업데이트된 배열을 setRoomAllList로 설정
        roomAllArray.current = updatedRoomAllArray;
        setRoomAllList([...roomAllArray.current]);

        //console.log(roomAllArray);
        //setRoomAllList(roomAllArray);

        roomAllArray.current.map((item) => {
          setRoomTypeId(item.value.room_type_id);
          setRoomState(item.value.key);
        });

        // Create a map for quick lookup by key
        const roomDataMap = resArray.reduce((acc, room) => {
          acc[room.key] = room;
          return acc;
        }, {});

        //roomAllArray.current = resArray;
        roomAllArray.current = roomDataMap;
        console.log("roomDataMap ==>", roomDataMap);

        // Fetch room layout configuration
        const resConfig = await getCustomConfigApi("roomlayout");
        console.log(Object.entries(resConfig.custom_configs).length);
        
        let { groupedCards } = Object.entries(resConfig.custom_configs).length < 1 ? { groupedCards: [{ group: "no", cards: [] }]} : Object.entries(resConfig.custom_configs)[0][1];
        console.log(groupedCards);
        
        if (!groupedCards.length) {
          groupedCards = [{ group: "no", cards: [] }];
        }

        const newGroups = groupedCards.filter((group) => group.group !== "default")
        .map((group) => ({
          id: group.group,
          name: group.groupName || `Group ${group.group}`,
        }));

        let updatedRoomList = groupedCards.flatMap((group) =>
          group.cards.map((card) => ({
            key: card.key,
            value: roomDataMap[card.key]?.value || {}, // Use value from API or empty object
            type: card.type || "standard",
            no: card.no,
            roomTypeId: roomDataMap[card.key]?.roomTypeId || null,
            roomSaleId: roomDataMap[card.key]?.roomSaleId || null,
            group: group.group,
          }))
        );

        if( updatedRoomList.length === 0 ){
          updatedRoomList = resArray.map((room) => ({
            ...room,
            group: "no",
          }));
        }

        setGroups(newGroups);
        setRoomAllList(updatedRoomList);
      }

      // queue_count 값 확인 및 ACCEPT 명령 전송
      if (parsedData.queue_count && parsedData.queue_count > 0) {
        setQueueCount(parsedData.queue_count);
        
        const acceptMessage = JSON.stringify({ command: 'ACCEPT' });
        ws.send(acceptMessage);
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
      setSocket(null);
      attemptReconnect(); // 연결이 끊어졌을 때 재연결 시도
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setSocket(null);
      attemptReconnect(); // 에러 났을 때 재연결 시도
    };

    setSocket(ws); // 소켓 객체 저장

    // return () => {
    //   ws.close();
    //   console.log('WebSocket close');
    // };
  };

  // 재연결 시도 함수
  const attemptReconnect = () => {
    if (reconnectAttempts.current < maxReconnectAttempts) {
      reconnectAttempts.current += 1;
      console.log(`Reconnecting... attempt ${reconnectAttempts.current}`);
      
      // 일정 시간 후에 재연결 시도
      setTimeout(() => {
        connectWebSocket();
      }, reconnectInterval);
    } else {
      console.error('Max reconnect attempts reached.');
      return;
    }
  };

  const refreshRoom = () => {
    setRefresh(true);
  };

  // ========================================================
  // Room all-by-accom : accom에 등록된 모든 room 정보
  useEffect(() => {
    const fetchData = async () => {
      try {
        setRefresh(false);
        const res = await roomAllByAccomSearchApi();

        const resArray = Object.entries(res.rooms).map(([key, value]) => ({
          key: key,
              value: value,
              no: value.display_name,
              roomTypeId: value.room_type_id,
              roomSaleId: value.room_sale_id,
        })).sort((a, b) => b.no - a.no);

        // Create a map for quick lookup by key
        const roomDataMap = resArray.reduce((acc, room) => {
          acc[room.key] = room;
          return acc;
        }, {});

        roomAllArray.current = resArray;

        // Fetch room layout configuration
        const resConfig = await getCustomConfigApi("roomlayout");
        console.log(Object.entries(resConfig.custom_configs).length);
        
        let { groupedCards } = Object.entries(resConfig.custom_configs).length < 1 ? { groupedCards: [{ group: "no", cards: [] }]} : Object.entries(resConfig.custom_configs)[0][1];
        console.log(groupedCards);

        if (!groupedCards.length) {
          groupedCards = [{ group: "no", cards: [] }];
        }

        const newGroups = groupedCards.filter((group) => group.group !== "default")
        .map((group) => ({
          id: group.group,
          name: group.groupName || `Group ${group.group}`,
        }));

        let updatedRoomList = groupedCards.flatMap((group) =>
          group.cards.map((card) => ({
            key: card.key,
            value: roomDataMap[card.key]?.value || {}, // Use value from API or empty object
            type: card.type || "standard",
            no: card.no,
            roomTypeId: roomDataMap[card.key]?.roomTypeId || null,
            roomSaleId: roomDataMap[card.key]?.roomSaleId || null,
            group: group.group,
          }))
        );

        if( updatedRoomList.length === 0 ){
          updatedRoomList = resArray.map((room) => ({
            ...room,
            group: "no",
          }));
        }

        setGroups(newGroups);
        setRoomAllList(updatedRoomList);
        
        if (!socket || socket.readyState === WebSocket.CLOSED || socket.readyState === WebSocket.CLOSING) {
          connectWebSocket();
        }

      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    return () => {
      // 컴포넌트 언마운트 시 웹소켓이 연결된 경우 닫음
      if (socket && socket.readyState !== WebSocket.CLOSING) {
        socket.close();
        setSocket(null); // 소켓 초기화
      }
    };
  }, [refresh]);

  // ========================================================
  // RoomType all-by-accom : accom에 등록된 모든 room_type 정보
  const [roomTypeAllList, setRoomTypeAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomTypeAllByAccomSearchApi();
        const resArray = Object.entries(res.room_types);
        const roomTypeAllArray = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
          };
        });
        setRoomTypeAllList(roomTypeAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // ===================================================================
  // RoomSale : 매출 정보를 가져온다.
  /*
  const [roomSaleAllList, setRoomSaleAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setRoomSaleId(roomAllList.roomSaleId);
        const res = await roomSaleInfoOnlyApi(roomSaleId);
        const resArray = Object.entries(res.room_sales);
        const roomSaleAllArray = resArray.map(([key, value]) => {
          // console.log(key === "Pq6TwLoGaQjs70QISL9W" ? value : "");
          return {
            key: key,
            value: value,
          };
        });
        setRoomSaleAllList(roomSaleAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  */

  // ========================================================
  // RoomReserve : 예약 정보를 가져온다.
  const [roomReserveAllList, setRoomReserveAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomReserveAllByAccomSearchApi();
        const resArray = Object.entries(res.room_reserves);
        const roomReserveAllArray = resArray.map(([key, value]) => {
          // console.log(key === "T3b982r00EydvgDxtDpS" ? value : "");
          return {
            key: key,
            value: value,
          };
        });
        setRoomReserveAllList(roomReserveAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // ========================================================

  return (
    <div className="room-wrap">
      {/* <div className="btn-group">
        <Button type="primary" onClick={sortNames}>
          이름순
        </Button>
      </div> */}
      <div>
        {groups.map((group) => (
          <div key={group.id} style={{ marginBottom: "24px" }}>
            {/* 그룹에 속한 카드 렌더링 */}
            <div className="grid-col-10" style={{ marginBottom: "16px" }}>
              {roomAllList
                .filter((room) => room.group === group.id)
                .map((rooms, idx) => (
                  (checkedListRoomControl.length > 0 ? (checkedListRoomControl?.find((checked)=>checked === rooms.value.state_summary)?true:false) : true) &&
                  <div key={`room-${idx}`} style={{ marginBottom: "8px" }}>
                    {rooms.type === "empty" ? (
                      <div
                        style={{
                          //border: "1px dashed #ccc",
                          padding: "10px",
                          textAlign: "center",
                          color: "#aaa",
                        }}
                      >
                        
                      </div>
                    ) : (
                      <Card
                        rooms={rooms}
                        roomTypeAllList={roomTypeAllList}
                        roomReserveAllList={roomReserveAllList}
                        refreshRoom={refreshRoom}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
      
    </div>
  );
};
