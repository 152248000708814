import React, {useState, useEffect} from "react";
import { Table, Button, Input } from "antd";
import { useAuth } from "./AuthContext";
import { useCode } from "./CodeContext";
import { api } from "../api/api";

export const SelectAccom = ({accessibility_admin, accomIdList, token, colseModal}) => {
    const [accomList, setAccomList] = useState(null);
    const [filtered, setFiltered] = useState(null);
    const [searchText, setSearchText] = useState("");
    const { selectAccom } = useAuth();
    const { codeload } = useCode();

    useEffect(() => {
        const fetchData = async () => {
            try {
                if(accessibility_admin){
                    const res = await api.get(`/accom-search`, {
                        params: {},
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    const resArray = Object.entries(res.data.accoms || {}).map(([key, value]) => ({
                        key: key,
                        name: value.name,
                        tel: value.tel,
                        business_no: value.business_no,
                    }));
                    console.log(resArray);
                    setAccomList(resArray);
                    setFiltered(resArray);
                }else{
                    const accoomArray = await Promise.all(
                        accomIdList.map(async (id) => {
                            const res = await api.get(`/accom/${id}`, {
                                params: {},
                                headers: { Authorization: `Bearer ${token}` },
                            });
                            const resArray = Object.entries(res.data.accoms || {});
                            console.log(resArray);
                            if (resArray.length === 0) {
                                console.warn(`No accom data found for ID: ${id}`);
                                return null; // 데이터가 없을 경우 null 반환
                            }
                            return {
                                key: id,
                                name: resArray[0][1].name,
                                tel: resArray[0][1].tel,
                                business_no: resArray[0][1].business_no,
                            }
                        })
                    );
                    const validAccoms = accoomArray.filter(Boolean); // null 값 필터링
                    setAccomList(validAccoms);
                    setFiltered(validAccoms);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const selectAccomFunc = async (accomId) => {
        console.log("move room => ", accomId);

        selectAccom(accomId); // AuthContext : 로그인 상태 업데이트

        // CodeContext : 공통 코드 업데이트
        if (accomId !== null) {
            const resRoomCode = await api.get(`/room/all-by-accom/${accomId}`, {
            params: {},
            headers: { Authorization: `Bearer ${token}` },
            });
            console.log(resRoomCode);
            codeload("room", resRoomCode.data.rooms);

            const resRoomTypeCode = await api.get(
            `/room-type/all-by-accom/${accomId}`,
            {
                params: {},
                headers: { Authorization: `Bearer ${token}` },
            }
            );
            codeload("roomType", resRoomTypeCode.data.room_types);
        }

        codeload("state", {
            GENERATED: "자동예약",
            RESERVED: "정상예약",
            USING: "사용중",
            CANCELED: "예약취소",
            USED: "사용완료",
            EXPIRED: "시간초과"
        });
        codeload("stayType", {
            HOURS: "대실",
            DAYS: "숙박",
            LONG_DAYS: "장기",
            OTHER: "기타",
        });
        codeload("agentType", {
            YANOLJA: "야놀자(M)",
            YANOLJA_HOTEL: "야놀자(H)",
            GOOD_CHOICE: "여기어때(M)",
            GOOD_CHOICE_HOTEL: "여기어때(H)",
            NAVER: "네이버",
            AIR_BNB: "에어비엔비",
            AGODA: "아고다",
            EXPEDIA: "익스피디아",
            ONDA: "온다",
            BOOKING_HOLDINGS: "부킹닷컴",
            KULL_STAY: "꿀스테이",
            DDNAYO: "떠나요",
            FRONT: "프론트",
            OTHER: "기타",
        });
        codeload("stateType", {
            EMPTY: "퇴실",
            USING: "입실",
            OUTING: "외출",
            CLEANING: "청소중",
            INSPECT_ORDER: "점검요청",
            INSPECTING: "점검중",
            CLEAN_ORDER: "청소요청",
        })

        colseModal();
        window.location.href = "/";
    }

    // 검색 input(입력폼)
    const handleSearch = (value) => {
        console.log(value);
        const filtered = accomList.filter(
        (item) =>
            item.name.includes(value) 
        );
        setFiltered(filtered);
        setSearchText(value);
    };
    
    // 테이블 컬럼 생성
    const columns = [
        {
            title: "업소명",
            dataIndex: "name",
            key: "name",
            width: "35%",
            align: "center",
        },
        {
            title: "전화번호",
            dataIndex: "tel",
            key: "tel",
            width: "15%",
            align: "center",
        },
        {
            title: "사업자",
            dataIndex: "business_no",
            key: "business_no",
            width: "15%",
            align: "center",
        },
        {
            title: '',
            key: 'operation',
            width: "10%",
            render: (record) => <Button onClick={() => selectAccomFunc(record.key)}>선택</Button>,
        },
    ];

    return (
        <>
          <div className="mt-16 mb-8">
            <div className="flex-row flex-wrap gap-8 flex-justify-end">
                <Input
                    placeholder="업소명으로 검색하세요"
                    value={searchText}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: "18.75rem" }}
                />   
            </div>
          </div>
          
          <div className="table-wrap-antd">
            <Table
              columns={columns}
              dataSource={filtered}
              //onChange={handleChange}
              pagination={false}
              rowKey="key"
              scroll={{
                y: "28.8rem",
                x: "300px",
              }}
              className="ant-table-respons"
            />
          </div>
        </>
    )
};