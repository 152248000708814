import React, { createContext, useContext, useEffect, useState } from "react";

const CodeContext = createContext();

export const CodeProvider = ({ children }) => {
  const [code, setCode] = useState(() => {
    // 초기화 시 sessionStorage에서 데이터를 로드
    const loaded = sessionStorage.getItem("code");
    return loaded ? JSON.parse(loaded) : {};
  });

  const codeload = (codekey, codeData) => {
    // 상태를 복사 및 업데이트
    code[codekey] = codeData;
    setCode(code); // React 상태 업데이트
    sessionStorage.setItem("code", JSON.stringify(code)); // sessionStorage에 동기화
  };

  const coderead = () => {
    return code;
  };

  const removeCode = () => {
    setCode({});
    sessionStorage.removeItem("code");
  };

  return (
    <CodeContext.Provider value={{ code, codeload, coderead, removeCode }}>
      {children}
    </CodeContext.Provider>
  );
};

export const useCode = () => {
  return useContext(CodeContext);
};