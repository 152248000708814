import React, { useEffect, useState, useRef } from "react";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableItem } from "../menuEdit/SortableItem"; // 새롭게 작성할 SortableItem 컴포넌트
import { Card } from "./Card";
import {
  roomAllByAccomSearchApi,
  roomReserveAllByAccomSearchApi,
  roomSaleInfoOnlyApi,
  roomTypeAllByAccomSearchApi,
  getCustomConfigApi,
  postCustomConfigApi,
  putCustomConfigApi
} from "../api/api";
import { Button, Divider, message, Modal } from "antd";
import { DeleteOutlined, AlertOutlined } from "@ant-design/icons";

export const EditMain = () => {
  const [roomAllList, setRoomAllList]  = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [roomTypeId, setRoomTypeId] = useState([]);
  const [roomState, setRoomState] = useState([]);

  const [socket, setSocket] = useState(null);

  // roomAllArray를 useRef로 선언
  const roomAllArray = useRef([]);

  const refreshRoom = () => {
    setRefresh(true);
  };

  // ========================================================
  // Room all-by-accom : accom에 등록된 모든 room 정보
  useEffect(() => {
    const fetchData = async () => {
      try {
        setRefresh(false);
        const res = await roomAllByAccomSearchApi();

        const resArray = Object.entries(res.rooms).map(([key, value]) => ({
          key: key,
              value: value,
              no: value.display_name,
              roomTypeId: value.room_type_id,
              roomSaleId: value.room_sale_id,
              group: value.floor,
        })).sort((a, b) => b.no - a.no);

        // Create a map for quick access to room data by key
        const roomDataMap = resArray.reduce((acc, room) => {
          acc[room.key] = room;
          return acc;
        }, {});

        roomAllArray.current = resArray;
        setRoomAllList([...roomAllArray.current]);

        roomAllArray.current.map((item) => {
          setRoomTypeId(item.value.room_type_id);
          setRoomState(item.value.key);
        });
        
        const resConfig = await getCustomConfigApi("roomlayout");
        let { groupedCards } = Object.entries(resConfig.custom_configs).length < 1 ? { groupedCards: [{ id: "default", cards: [] }]} : Object.entries(resConfig.custom_configs)[0][1];
        
        if (!groupedCards.length) {
          groupedCards = [{ id: "default", cards: [] }];
        }

        // Update groups and room list based on fetched data
        const newGroups = groupedCards.map((group) => ({
          id: group.group,
          name: group.groupName || `Group ${group.group}`,
        }));
  
        let newRoomList = groupedCards.flatMap((group) =>
          group.cards.map((card) => ({
            key: card.key,
            no: card.no,
            type: card.type || "standard",
            group: group.group,
            value: roomDataMap[card.key]?.value || {}, // Use state_summary from roomDataMap
          }))
        );
        
        if( newRoomList.length === 0 ){
          newRoomList = resArray.map((room) => ({
            ...room,
            group: "default",
          }));
        }else{
          let groupedCardKeys = new Set(
            groupedCards.flatMap((group) => group.cards.map((card) => card.key))
          );
          
          let ungroupedCards = Object.values(roomDataMap).filter(
            (room) => !groupedCardKeys.has(room.key)
          );

          newRoomList = [
            ...newRoomList,
            ...ungroupedCards.map((room) => ({
              key: room.key,
              no: room.no,
              type: "standard",
              group: "default",
              value: room.value,
            })),
          ];
        }

        setGroups(newGroups);
        setRoomAllList(newRoomList);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [refresh]);

  // ========================================================
  // RoomType all-by-accom : accom에 등록된 모든 room_type 정보
  const [roomTypeAllList, setRoomTypeAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomTypeAllByAccomSearchApi();
        const resArray = Object.entries(res.room_types);
        const roomTypeAllArray = resArray.map(([key, value]) => {
          return {
            key: key,
            value: value,
          };
        });
        setRoomTypeAllList(roomTypeAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // ========================================================
  // RoomReserve : 예약 정보를 가져온다.
  const [roomReserveAllList, setRoomReserveAllList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await roomReserveAllByAccomSearchApi();
        const resArray = Object.entries(res.room_reserves);
        const roomReserveAllArray = resArray.map(([key, value]) => {
          // console.log(key === "T3b982r00EydvgDxtDpS" ? value : "");
          return {
            key: key,
            value: value,
          };
        });
        setRoomReserveAllList(roomReserveAllArray);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // ========================================================
  const [isDragging, setIsDragging] = useState(false);
  const [groups, setGroups] = useState([]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10, // 드래그를 활성화하려면 최소 10px 이상 이동해야 함
      },
    })
  );
  
  useEffect(() => {
    // roomAllList 기반으로 그룹 생성
    const uniqueGroups = Array.from(
      new Set(roomAllList.map((room) => room.group))
    ).map((groupId) => ({ id: groupId, name: `Group ${groupId}` }));

    const defaultGroup = { id: "default", name: "Default Group" };

    setGroups([...uniqueGroups.filter(group => group.id !== "default"), defaultGroup]);
  }, [roomAllList]);

  const addGroup = () => {
    const newGroup = {
      id: `group-${Date.now()}`,
      name: `Group ${groups.length + 1}`,
    };
    setGroups((prevGroups) => {
      const updatedGroups = [...prevGroups, newGroup];
      addEmptySlot(newGroup.id); // 새 그룹에 빈 슬롯 추가
      return updatedGroups;
    });
  };

  const handleDragEnd = (event) => {    
    const { active, over } = event;

    if (!active || !over) return;

    const activeIndex = roomAllList.findIndex((room) => room.key === active.id);
    const activeRoom = roomAllList[activeIndex];

    if (!activeRoom) return;

    const updatedList = [...roomAllList];

    const overIndex = roomAllList.findIndex((room) => room.key === over.id);

    if (overIndex !== -1) {
      // 동일 그룹 내 정렬 변경 또는 그룹 간 이동
      const [movedRoom] = updatedList.splice(activeIndex, 1);

      // 그룹 변경
      const targetGroup = roomAllList[overIndex].group;
      movedRoom.group = targetGroup;
      updatedList.splice(overIndex, 0, movedRoom);

      // Remove empty slot from target group if exists
      removeEmptySlot(targetGroup);

      // Log card count in the target group
      const targetGroupCardCount = updatedList.filter((room) => room.group === targetGroup).length;
      console.log(`Group ${targetGroup} now has ${targetGroupCardCount} cards.`);
    } else {
      // 그룹이 비어있는 경우 그룹 변경
      const overGroup = groups.find((group) => group.id === over.id);
      if (overGroup) {
        const [movedRoom] = updatedList.splice(activeIndex, 1);
        movedRoom.group = overGroup.id;
        updatedList.push(movedRoom);

        // Remove empty slot from target group if exists
        removeEmptySlot(overGroup.id);

        // Log card count in the target group
        const targetGroupCardCount = updatedList.filter((room) => room.group === overGroup.id).length;
        console.log(`Group ${overGroup.id} now has ${targetGroupCardCount} cards.`);
      }
    }

    // Add an empty slot to the source group if it becomes empty
    const sourceGroup = activeRoom.group;
    if (!updatedList.some((room) => room.group === sourceGroup)) {
      addEmptySlot(sourceGroup);
    }

    setRoomAllList(updatedList);
  };

  // 빈칸 추가 함수
  const addEmptySlot = (groupId) => {
    setRoomAllList((prevList) => [
      ...prevList,
      {
        key: `empty-${Date.now()}`,
        type: "empty",
        value: { state_summary: "" },
        group: groupId,
      },
    ]);
  };

  const removeEmptySlot = (groupId) => {
    setRoomAllList((prevList) =>
      prevList.filter((room) => !(room.group === groupId && room.type === "empty"))
    );
  };

  const handleDeleteCard = (cardKey, e) => {
    setRoomAllList((prevList) => {
      const updatedList = prevList.map((room) =>
        room.key === cardKey ? { ...room, group: "default" } : room
      );
      return updatedList;
    });
  };

  const handleDeleteEmptySlot = (slotKey) => {
    setRoomAllList((prevList) =>
      prevList.filter((room) => room.key !== slotKey)
    );
  };

  const handleDeleteGroup = (groupId) => {
    setRoomAllList((prevList) =>
      prevList.map((room) =>
        room.group === groupId ? { ...room, group: "default" } : room
      )
    );
    setGroups((prevGroups) => prevGroups.filter((group) => group.id !== groupId));
  };

  const handleSave = async () => {
    Modal.confirm({
      title: "저장하시겠습니까?",
      width: "440px",
      icon: (
        <AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />
      ),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk: async () => {
        // 그룹별 매핑된 카드 리스트 생성
        const groupedCards = groups.map((group) => {
          const cardsInGroup = roomAllList.filter((room) => room.group === group.id);
          return {
            group: group.id,
            cards: cardsInGroup.map((room) => ({
              key: room.key,
              no: room.no,
              type: room.type,
            })),
          };
        });

        let param = {
          //"groups": groups
          "groupedCards" : groupedCards
        }
        
        try {
          const res = await putCustomConfigApi("roomlayout", param);
          
          if (Object.entries(res.custom_configs).length > 0) {
            message.info("저장되었습니다.");
          } else {
            message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
          }
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
      },
    });
    
  };

  const handleInit =  () => {
    Modal.confirm({
      title: "초기화하시겠습니까?",
      width: "440px",
      icon: (
        <AlertOutlined style={{ transform: "scale(1.3)", color: "#a46bff" }} />
      ),
      okText: "예",
      cancelText: "아니오",
      centered: true,
      onOk: async () => {
        let param = {
          "groupedCards" : []
        }
        
        try {
          const res = await putCustomConfigApi("roomlayout", param);
          
          if (Object.entries(res.custom_configs).length > 0) {
            message.info("저장되었습니다.");
            window.location.reload();
          } else {
            message.error("저장중 문제가 발생했습니다. 다시 저장해주세요.");
          }
        } catch (error) {
          console.log(error);
        }
      },
      onCancel() {
      },
    });
    
  };

  return (
    <div className="room-wrap">
      <div className="">
        <Button className="mr-4" onClick={handleInit}>설정 초기화</Button>
        <Button className="mr-4" onClick={addGroup}>
          그룹 추가
        </Button>
        <Button type="primary" onClick={handleSave}>
          저장
        </Button>
      </div>
      <DndContext
        sensors={sensors} 
        onDragStart={() => {
          setIsDragging(true); // 드래그 시작
        }}
        onDragEnd={(event) => {
          setIsDragging(false); // 드래그 종료
          handleDragEnd(event);
        }}
      >
        <SortableContext
          items={roomAllList.map((room) => room.key)}
          strategy={rectSortingStrategy}
        >
          {groups.map((group) => (
            <div key={group.id} style={{ marginBottom: "24px" }}>
              {/*<h3>{group.name}</h3>*/}
              <Divider style={{ borderColor: "#7c3ef2"}} orientation="right">
                {group.id === 'default' ? (<span style={{ fontSize: "20px", fontWeight: "bold", marginRight: "20px" }}>미지정</span>) : ''}
                <Button className="mr-4" onClick={() => addEmptySlot(group.id)}>빈칸 추가</Button>
                <Button onClick={() => handleDeleteGroup(group.id)}>그룹 삭제</Button>
              </Divider>
              <div
                className="grid-container"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                  gap: "16px",
                }}
              >
                {roomAllList
                  .filter((room) => room.group === group.id)
                  .map((rooms, idx) => (
                    
                    <SortableItem key={rooms.key} id={rooms.key}>
                      {rooms.type !== "empty" ? (
                        <div key={`rooms-${idx}`}>
                          <Card
                            rooms={rooms}
                            roomTypeAllList={roomTypeAllList}
                            roomReserveAllList={roomReserveAllList}
                            refreshRoom={refreshRoom}
                          />
                          <Button type="default" className="mt-8 w-full" 
                            data-ignore-dnd // 이 속성으로 버튼 클릭 이벤트가 드래그 이벤트와 분리됨
                            onClick={(e) => {
                              e.stopPropagation(); // 이벤트 전파 방지
                              handleDeleteCard(rooms.key, e); // 삭제 함수 호출
                            }}>
                              
                            <DeleteOutlined />
                          </Button>
                          
                        </div>
                      ) : (
                        <>
                          <div
                            style={{
                              border: "2px dashed #ccc",
                              borderRadius: "8px",
                              padding: "16px",
                              textAlign: "center",
                              background: "#f9f9f9",
                              color: "#aaa",
                              minHeight: "10.175rem"
                            }}
                          >
                            Empty Slot
                          </div>
                          <Button
                            type="default"
                            className="mt-8 w-full"
                            onClick={() => handleDeleteEmptySlot(rooms.key)}
                          >
                            <DeleteOutlined />
                          </Button>
                        </>
                      )}
                    </SortableItem>
                  ))}
              </div>
            </div>
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};
