import React from "react";
import stateIconClean from "../images/icon/broom-unstroke.png";
import stateIconCheckIn from "../images/icon/check-in.png";
import stateIconBed from "../images/icon/bed.png";
import stateIconInspection from "../images/icon/inspection.png";
import { useCode } from "../login/CodeContext";
import { TimeNoSecFormatterSymbol } from "../util";

export const Card = ({ rooms }) => {

  // 공통 코드 불러오는 부분
  const { coderead } = useCode();
  const code = coderead();
  const codeRoomType = Object.entries(code.roomType);
  const codeRoomTypeList = codeRoomType.map(([key, value]) => {
    return {
      key: key,
      value: value.display_name,
    };
  });

  return (
    <>
      <div className="card-wrap">
        <div>
          {/* 상태표시 - 바 */}
          <div
            className={
              rooms.value.state_summary === "USING"
                ? "state-bar red"
                : rooms.value.state_summary === "OUTING"
                ? "state-bar red"
                : rooms.value.state_summary === "CLEAN_ORDER" || rooms.value.state_summary === "CLEANING"
                ? "state-bar green"
                : rooms.value.state_summary === "INSPECT_ORDER"
                ? "state-bar purple"
                : rooms.value.state_summary === "INSPECTING"
                ? "state-bar orange"
                : "state-bar"
            }
          ></div>

          <div className="room-card-tit">
            <h3>{rooms.value.display_name}호 -&nbsp;</h3>
            <h3 className="ellipsis">
              {codeRoomTypeList?.find(
                (roomType) => roomType.key === rooms.value.room_type_id
              )?.value || ""}
            </h3>
          </div>

          {/* 상태표시 - 아이콘 */}
          <div className="state-icons">
            <div className="state-btn">
            </div>

            <div className="flex-col w-full" style={{ height: "108px" }}>
              {/* 상태표시 - 이미지 */}
              <div className="img-wrap">
                {rooms.value.state_summary === "CLEANING" ? (
                  <div className="flex-col-center">
                    <img src={stateIconClean} alt="청소요청 아이콘" />
                      <span className="green">청소중</span>
                  </div>
                ) : rooms.value.clean_order ? (
                  <div className="flex-col-center">
                    <img src={stateIconClean} alt="청소요청 아이콘" />
                      <span className="green">청소요청</span>
                  </div>
                ) : rooms.value.inspect_order ? (
                  <div className="flex-col-center">
                    <img src={stateIconInspection} alt="점검요청 아이콘" />
                    <span>점검요청</span>
                  </div>
                ) : rooms.value.state_summary === "INSPECTING" ? (
                  <div className="flex-col-center">
                    <img src={stateIconInspection} alt="점검중 아이콘" />
                    <span>점검중</span>
                  </div>
                ) : rooms.value.state_summary === "USING" ? (
                  <div className="flex-col-center">
                    <img src={stateIconCheckIn} alt="체크인 아이콘" />
                    <span className="red">입실</span>
                  </div>
                ) : rooms.value.state_summary === "OUTING" ? (
                  <div className="flex-col-center">
                    <img src={stateIconCheckIn} alt="체크인 아이콘" />
                    <span className="red">외출</span>
                  </div>
                ) : (
                  <img src={stateIconBed} alt="기본 아이콘" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="result">
          {rooms.value.state_summary === "CLEAN_ORDER" || rooms.value.state_summary === "CLEANING"
            ? "청소"
            : rooms.value.state_summary === "USING"  || rooms.value.state_summary === "OUTING"
            ? "입실"
            : rooms.value.state_summary === "EMPTY" ||
              rooms.value.state_summary === "INSPECT_ORDER" ||
              rooms.value.state_summary === "INSPECTING"
            ? "퇴실"
            : null}

          {rooms.value.state_summary === "CLEAN_ORDER" || rooms.value.state_summary === "CLEANING" ? (
            <>
              (
              <TimeNoSecFormatterSymbol
                dateTime={rooms.value.last_clean_order_start_time}
              />
              )
            </>
          ) : rooms.value.state_summary === "USING"  || rooms.value.state_summary === "OUTING" ? (
            <>
              (
              <TimeNoSecFormatterSymbol
                dateTime={rooms.value.last_check_in_time}
              />
              )
            </>
          ) : rooms.value.state_summary === "EMPTY" ? (
            <>
              (
              <TimeNoSecFormatterSymbol
                dateTime={rooms.value.last_check_out_time}
              />
              )
            </>
          ) : rooms.value.state_summary === "INSPECT_ORDER" ? (
            <>
              (
              <TimeNoSecFormatterSymbol
                dateTime={rooms.value.last_inspect_order_start_time}
              />
              )
            </>
          ) : rooms.value.state_summary === "INSPECTING" ? (
            <>
              (
              <TimeNoSecFormatterSymbol
                dateTime={rooms.value.last_check_out_time}
              />
              )
            </>
          ) : null}
        </div>

      </div>
    </>
  );
};
