import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export const SortableItem = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    userSelect: "none",
    background: "#fff",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    cursor: "grab",
    pointerEvents: "auto", // 클릭 이벤트 허용
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}
    onMouseDown={(e) => e.stopPropagation()} > 
      {children}
    </div>
  );
};